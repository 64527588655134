import { Capacitor } from "@capacitor/core";
import { NFC, NfcTag } from "@ionic-native/nfc";
import { logError } from "@src/appV2/lib/analytics";
import { DeviceNFCCapabilityForShift } from "@src/lib/interface/src/lib/shift";

import { MockNFCHelper } from "./mockNfcHelper";
import { USE_MOCKED_NFC_FOR_DEVELOPMENT } from "../../constants";

export type FacilityNFCHash = {
  nfcHash: string;
  isActive: boolean;
};

const parseNfcTag = (tag: NfcTag) => {
  let payload = "";
  if (tag.ndefMessage) {
    const [firstTagMessage] = tag.ndefMessage;
    payload = NFC.bytesToString(firstTagMessage?.payload);
  }
  return payload;
};

let readingTag = false;

const startReaderModeForAndroid = async (
  onSuccessRead: (result: string) => void
): Promise<boolean> => {
  const flags = NFC.FLAG_READER_NFC_A | NFC.FLAG_READER_NFC_V;
  return new Promise((resolve) => {
    NFC.readerMode(flags).subscribe(
      (data) => {
        if (readingTag) {
          readingTag = false;
          onSuccessRead(parseNfcTag(data));
          resolve(true);
        }
      },
      (error) => {
        if (readingTag) {
          logError("startReaderModeForAndroid error", { error });
          readingTag = false;
          resolve(true);
        }
      }
    );
  });
};

const startNdefScan = async (onSuccessRead: (result: string) => void) => {
  try {
    const tag = await NFC.scanNdef();
    return onSuccessRead(parseNfcTag(tag));
  } catch (error) {
    logError("startNdefScan error", { error });
    return "";
  }
};

const readNFCTagInformation = async (
  onSuccessRead: (result: string) => void
): Promise<string | undefined> => {
  const platform = Capacitor.getPlatform();
  if (platform === "ios") {
    await startNdefScan(onSuccessRead);
    return;
  } else if (platform === "android") {
    readingTag = true;
    await startReaderModeForAndroid(onSuccessRead);
    return;
  }
  return "";
};

const cancelNfcScan = async () => {
  if (Capacitor.getPlatform() === "ios") {
    await NFC.cancelScan();
  }
};

const deviceNfcCapabilityForShift = async () => {
  try {
    if (!Capacitor.isNativePlatform() || !NFC) {
      return DeviceNFCCapabilityForShift.NO_NFC;
    }

    await NFC.enabled();
    return DeviceNFCCapabilityForShift.NFC_ENABLED;
  } catch (error) {
    console.warn("Error in checking NFC capabilities:", error);
    return error;
  }
};

export const deviceNFCCapabilityForShift = USE_MOCKED_NFC_FOR_DEVELOPMENT
  ? MockNFCHelper.deviceNFCCapabilityForShift
  : deviceNfcCapabilityForShift;
export const readNFCTagInfo = USE_MOCKED_NFC_FOR_DEVELOPMENT
  ? MockNFCHelper.readNFCTagInfo
  : readNFCTagInformation;
export const cancelNFCScan = USE_MOCKED_NFC_FOR_DEVELOPMENT
  ? MockNFCHelper.cancelNFCScan
  : cancelNfcScan;
