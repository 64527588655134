import { ShiftStages } from "@src/lib/interface/src";

export const nfcSuccessMessage = (currentStage: ShiftStages) => {
  switch (currentStage) {
    case ShiftStages.CLOCK_IN:
      return "You clocked in.";
    case ShiftStages.CLOCK_OUT:
      return "You clocked out.";
    case ShiftStages.LUNCH_IN:
      return "You ended your break.";
    case ShiftStages.LUNCH_OUT:
      return "You started a break.";
    case ShiftStages.SKIP_LUNCH:
      return "You skipped your break.";
    case ShiftStages.GET_FACILITY_SIGNATURE:
      return "You edited your times.";
    default:
      return "You performed an event.";
  }
};
