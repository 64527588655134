import { CustomAlert } from "@src/app/hcpShifts/shiftDetails/alerts";
import { hackAlertChange } from "@src/app/utils/alertChangeHelper";
import { NFCReadStatus } from "@src/lib/interface/src";
import { useState } from "react";

import {
  alertBrowserNFCAccessMessage,
  alertCancelEditedTimeMessage,
  alertContactHCFAdminMessage,
  alertNFCAccessMessage,
  alertNoNetworkConnectionMessage,
  alertOtherIssueMessage,
  alertSkipNFCScanMessage,
  alertUserToRequestHCFToChangeToBackupMessage,
  getAlertNFCErrorMessage,
} from "./utils/nfcAlertMessage";

/**
 * @deprecated
 * These unusual `useAlert` code implementations are an attempt to display
 * a message to the user, along with call-to-action responses.
 * This is non idiomatic for React.
 * Instead, use a standard React component local to the code that
 * handles the notification, along with local action handlers.
 * This is not a standard React pattern and also creates convoluted
 * code patterns and code bloat.
 * This code pattern results in a non-trivial wiring of code in
 * handler functions.
 * Instead of this, the code should be encapsulated in separate
 * components, one for each alert dialog.
 */
export const useDeprecatedAlertsForNFC = () => {
  const [alert, setAlert] = useState<CustomAlert | null>(null);

  const dismissAlert = () => setAlert(null);

  const alertSkipNFCScan = (opts: {
    goBackBtnHandler: () => void;
    acceptNonInstantPayBtnHandler: () => void;
  }) => {
    setAlert({
      header: "Non-InstantPay",
      message: alertSkipNFCScanMessage,
      buttons: [
        {
          cssClass: "danger-color",
          text: "Accept Non-InstantPay",
          handler: opts.acceptNonInstantPayBtnHandler,
        },
        {
          text: "Go Back",
          handler: hackAlertChange(opts.goBackBtnHandler),
        },
      ],
    });
  };

  const alertNFCAccess = (opts: {
    openNFCSettingsFn: () => void;
    skipNfcBtnHandler: () => void;
    isEdit?: boolean;
  }) => {
    setAlert({
      header: "NFC Access",
      message: alertNFCAccessMessage,
      buttons: [
        {
          cssClass: "danger-color",
          text: "Skip NFC Scan",
          handler: opts.isEdit
            ? hackAlertChange(() => {
                alertCancelEditedTime({
                  goBackBtnHandler: () => {
                    alertNFCAccess(opts);
                  },
                  cancelEditBtnHandler: opts.skipNfcBtnHandler,
                });
              })
            : hackAlertChange(() => {
                alertSkipNFCScan({
                  goBackBtnHandler: () => {
                    alertNFCAccess(opts);
                  },
                  acceptNonInstantPayBtnHandler: opts.skipNfcBtnHandler,
                });
              }),
        },
        {
          text: "Grant Access",
          handler: opts.openNFCSettingsFn,
        },
      ],
    });
  };

  const alertNFCError = (opts: {
    showNFCNotWorking: boolean;
    isEdit?: boolean;
    nfcReadStatus: NFCReadStatus;
    tryAgainBtnHandler: () => void;
    skipNfcBtnHandler: () => void;
    okayButtonHandler: () => void;
  }) => {
    const buttons = [
      {
        cssClass: "danger-color",
        text: "Skip NFC Scan",
        handler: opts.isEdit
          ? hackAlertChange(() => {
              alertCancelEditedTime({
                goBackBtnHandler: () => {
                  alertNFCError(opts);
                },
                cancelEditBtnHandler: opts.skipNfcBtnHandler,
              });
            })
          : hackAlertChange(() => {
              alertSkipNFCScan({
                goBackBtnHandler: () => {
                  alertNFCError(opts);
                },
                acceptNonInstantPayBtnHandler: opts.skipNfcBtnHandler,
              });
            }),
      },
      {
        text: "Try Again",
        handler: hackAlertChange(opts.tryAgainBtnHandler),
      },
    ];
    // Add tried NFC scan minumum of 2 times
    if (opts.showNFCNotWorking) {
      buttons.push({
        text: "NFC is not working",
        handler: hackAlertChange(() => {
          return alertContactHCFAdmin({
            okayButtonHandler: opts.okayButtonHandler,
          });
        }),
      });
    }
    setAlert({
      header: "NFC Error",
      message: getAlertNFCErrorMessage(opts.nfcReadStatus),
      buttons,
    });
  };

  const alertBrowserNFCAccess = (opts: { gotItButtonHandler: () => void }) => {
    setAlert({
      header: "Open Browser Settings",
      message: alertBrowserNFCAccessMessage,
      buttons: [
        {
          text: "Got it",
          handler: opts.gotItButtonHandler,
        },
      ],
    });
  };

  const alertUserToRequestHCFToChangeToBackup = (opts: { gotItBtnHandler: () => void }) => {
    setAlert({
      header: "Device doesn’t support NFC",
      message: alertUserToRequestHCFToChangeToBackupMessage,
      buttons: [
        {
          text: "Got it",
          handler: opts.gotItBtnHandler,
        },
      ],
    });
  };

  const alertNoNetworkConnection = (opts: { gotItBtnHandler: () => void }) => {
    setAlert({
      header: "No Network Connection",
      message: alertNoNetworkConnectionMessage,
      buttons: [
        {
          text: "Got it",
          handler: opts.gotItBtnHandler,
        },
      ],
    });
  };

  const alertOtherIssue = (opts: { gotItButtonHandler: () => void }) => {
    setAlert({
      header: "Something went wrong",
      message: alertOtherIssueMessage,
      buttons: [
        {
          text: "Got it",
          handler: opts.gotItButtonHandler,
        },
      ],
    });
  };

  const alertContactHCFAdmin = (opts: { okayButtonHandler: () => void }) => {
    setAlert({
      header: "NFC is Not Working",
      message: alertContactHCFAdminMessage,
      buttons: [
        {
          text: "Okay",
          handler: opts.okayButtonHandler,
        },
      ],
    });
  };

  const alertCancelEditedTime = (opts: {
    goBackBtnHandler: () => void;
    cancelEditBtnHandler: () => void;
  }) => {
    setAlert({
      header: "Cancel Edit",
      message: alertCancelEditedTimeMessage,
      buttons: [
        {
          cssClass: "danger-color",
          text: "Do Not Edit Times",
          handler: opts.cancelEditBtnHandler,
        },
        {
          text: "Go Back",
          handler: hackAlertChange(opts.goBackBtnHandler),
        },
      ],
    });
  };

  return {
    alert,
    dismissAlert,
    alertNFCAccess,
    alertNFCError,
    alertBrowserNFCAccess,
    alertSkipNFCScan,
    alertUserToRequestHCFToChangeToBackup,
    alertNoNetworkConnection,
    alertOtherIssue,
    alertCancelEditedTime,
  };
};

export type NFCAlerts = ReturnType<typeof useDeprecatedAlertsForNFC>;
