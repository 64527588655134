import { EXTENDED_NFC_SCAN_MAX_TIME_IN_SECONDS } from "@src/constants/shift";
import {
  NFCReadStatus,
  NFCShiftConversionReason,
  Shift,
  ShiftStages,
} from "@src/lib/interface/src";
import { getHumanReadableTag } from "@src/lib/utils/src";
import moment from "moment-timezone";

export function getNFCReadLogReason(
  apiFailed = false,
  nfcReadStatus: NFCReadStatus | null = null,
  currentStage: ShiftStages,
  shift: Shift,
  isEdit?: boolean
): string {
  const whileClockOrEditingMessage = isEdit
    ? `While Editing and saving the time for shift ${shift._id} (starts at ${shift.start} and ends at ${shift.end}) in facility ${shift.facility?.name}.`
    : `While ${getHumanReadableTag(currentStage)} to shift ${shift._id} (starts at ${
        shift.start
      } and ends at ${shift.end}) in facility ${shift.facility?.name}.`;

  if (apiFailed) {
    return `NFC Validation Failure - ${whileClockOrEditingMessage} Reason: NFC hash verification API failed. Error details: NFC hash is inactive or not present for the given facility. nfcReadStatus=${nfcReadStatus}`;
  }

  switch (nfcReadStatus) {
    case null:
    case NFCReadStatus.SUCCESS:
      break;
    case NFCReadStatus.FAILED:
      return `NFC Read Failure - ${whileClockOrEditingMessage} Reason: NFC hash read failed. Error details: NFC scan failed, request the HCP keeps their phone close to the NFC tag until the process completes.`;
    case NFCReadStatus.INVALID_HASH:
      return `NFC Validation Failure - ${whileClockOrEditingMessage} Reason: NFC hash verification failed. Error details: NFC tag was not created by CBH.`;
    case NFCReadStatus.NETWORK_FAILURE:
      return `NFC Validation Failure - ${whileClockOrEditingMessage} Reason: NFC hash verification failed. Error details: Network request to validate the hash failed.`;
    case NFCReadStatus.INACTIVE_NFC_HASH:
      return `NFC Validation Failure - ${whileClockOrEditingMessage} Reason: NFC hash verification failed. Error details: NFC Hash is inactive for the facility.`;
    case NFCReadStatus.READ_TIMEOUT:
      return `NFC Read Failure - ${whileClockOrEditingMessage} Reason: NFC read timeout. Error details: NFC read process is taking more than ${EXTENDED_NFC_SCAN_MAX_TIME_IN_SECONDS} seconds.`;
    default:
      nfcReadStatus satisfies never;
      break;
  }

  return `NFC Read Failure - ${whileClockOrEditingMessage}. Reason: NFC hash verification failed. Error details: Unknown error occurred. apiFailed=${apiFailed} nfcReadStatus=${nfcReadStatus}.`;
}

export function getNFCShiftConversionLogReason(
  nfcShiftConversionReason: NFCShiftConversionReason | null = null,
  shiftId: string | undefined,
  currentStage?: ShiftStages,
  shift?: Shift
): string {
  let conversionReason = `While ${getHumanReadableTag(currentStage)} to shift ${
    shift?._id
  } (starts at ${shift?.start} and ends at ${shift?.end}) in facility ${
    shift?.facility?.name
  }, shift nfc check has been set to false`;

  if (nfcShiftConversionReason === NFCShiftConversionReason.SKIP_NFC_VALIDATION) {
    conversionReason = `Shift converted to non instant pay - While ${getHumanReadableTag(
      currentStage
    )} to shift ${shift?._id} (starts at ${shift?.start} and ends at ${shift?.end}) in facility ${
      shift?.facility?.name
    }.
    Reason: HCP skipped NFC verification`;
  }

  if (nfcShiftConversionReason === NFCShiftConversionReason.NFC_TAG_NOT_WORKING) {
    let conversionStage = getHumanReadableTag(currentStage);
    if (currentStage === ShiftStages.GET_FACILITY_SIGNATURE) {
      conversionStage = "Editing and saving";
    }
    conversionReason = `Shift converted to location check - While ${conversionStage} to shift ${shift?._id} (starts at ${shift?.start} and ends at ${shift?.end}) in facility ${shift?.facility?.name}.
    Reason: HCP reported that NFC tag read is not working.`;
  }

  if (nfcShiftConversionReason === NFCShiftConversionReason.NO_NFC_DEVICE) {
    conversionReason = `Shift converted to location check - shift ${shiftId} got converted at ${moment()}.
    Reason: NFC not supported by device.`;
  }
  return conversionReason;
}
